import { ReactComponent as BackIcon } from "../../icons/back-icon.svg";

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Dimmer, Loader, Radio } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { fetchReviews } from '../../components/Reviews/actions';
import { getReviewsFetching, getReviews } from '../../components/Reviews/reducer';
import Rating from '../../components/Rating';
import './style.css';
import { setYear } from '../Profile/actions';
import { fetchUserData } from '../../components/UserLogin/actions';



const yearOptions = [
    { key: 0, text: '2025', value: '2025'},
    { key: 1, text: '2024', value: '2024'},
]

const ReviewCard = (props) => {
    const {review, navigate} = props
    const formatedDate = review.timestamp.split(' ')[0].split('-').reverse().join('.')
    return (
        <div className='review-card'>
            <div className='review-card-number'
                 onClick={() => navigate(`../task/${review.id_task}`)}>№{review.id_task.split('-')[0]}</div>
            <div className='review-card-user-name'>{review.reviewer_user_name}</div>

            <div className='review-card-content'>
                <div className='review-card-header'>
                    <div className='review-card-header-ctn'>
                        <div className='review-card-title'>{review.product_name}</div>
                        <div className='review-card-date'>{formatedDate}</div>
                    </div>
                    <div className='review-card-rating'>
                        <Rating disabled maxRating={5} rating={review.rating}  icon='star' />
                    </div>
                </div>
                <div className='review-card-body'>
                    <div className='review-card-segment'>
                        <div className='review-card-field'>Клиент:</div>
                        <div className='review-card-field-content'>{review.customer_name}</div>
                    </div>
                    <div className='review-card-segment'>
                        <div className='review-card-field'>Плюсы:</div>
                        <div className='review-card-field-content'>{review.pros}</div>
                    </div>
                    <div className='review-card-segment'>
                        <div className='review-card-field'>Минусы:</div>
                        <div className='review-card-field-content'>{review.cons}</div>
                    </div>
                    <div className='review-card-segment'>
                        <div className='review-card-field'>Отзыв:</div>
                        <div className='review-card-field-content'>{review.comment}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};


function ReviewsList(props) {
    const {reviews} = props
    const loading = useSelector((state) => getReviewsFetching(state.reviews));

    if (loading === 1) {
        return <div>Загрузка отзывов...</div>;
    }

    if (!reviews || reviews.length === 0) {
        return <div style={{marginLeft: '15px', marginTop: '15px', fontSize: '15px', fontWeight: 'bold'}}>Отзывов пока нет</div>;
    }
    return (
        <div className='reviews-list'>
            {reviews.map((review) => (
                <ReviewCard {...props} review={review}/>
            ))}
        </div>
    );
}


export default function MyReviews(props) {
    const {navigate, dispatch, token } = props;
    const [selectedReviews, setSelectedReviews] = useState('customer');
    const reviewsAdmin = useSelector((state) => state.userLogin.userData.profiles.tasks.rating.admin);
    const reviewsCustomer = useSelector((state) => state.userLogin.userData.profiles.tasks.rating.customer);
    const stateYear = useSelector(state => state.yearReducer.selectedYear)
    const [userDataFetching, setUserDataFetching] = useState(false);

    const handleChangeYear = (e, {value}) => {
        dispatch(setYear(value));
        setUserDataFetching(true)
        dispatch(fetchUserData(token, 'ru', value))
            .finally(() => setUserDataFetching(false))
    }

    console.log(reviewsAdmin, reviewsCustomer)

    function handleChange(e, { value }) {
        setSelectedReviews(value);
    }

    useEffect(() => {
        if (token) {
            setUserDataFetching(true);
            dispatch(fetchUserData(token, 'ru', stateYear))
                .finally(() => setUserDataFetching(false));
        } else {
            navigate('/');
        }
    }, [token, stateYear, dispatch]);

    return (
        <>
            <div className="service-payment-container">
                <div className="service-payment-header">
                    <BackIcon className="service-payment-header-img" onClick={() => navigate('/profile')} />
                    <div className="title-text">Мои отзывы</div>
                    <Dropdown
                        style={{height: 'max-content', width: 'max-content', position: 'absolute', top: 0, right: 0, }}
                        className='reviews-dropdown'
                        fluid
                        name='Выберите год'
                        value={stateYear}
                        onChange={handleChangeYear}
                        search
                        selection
                        scrolling
                        noResultsMessage="Ничего не найдено"
                        options={yearOptions}
                    />
                </div>
                <div className="checkboxes-box">
                    <Radio
                        label="От клиента"
                        name="radioGroup"
                        value="customer"
                        checked={selectedReviews === 'customer'}
                        onChange={handleChange}
                        className="checkbox-box"
                    />
                    <Radio
                        label="От старшего специалиста"
                        name="radioGroup"
                        value="admin"
                        checked={selectedReviews === 'admin'}
                        onChange={handleChange}
                        className="checkbox-box"
                    />
                </div>
                <ReviewsList {...props} reviews={selectedReviews === 'admin' ? reviewsAdmin.reviews : reviewsCustomer.reviews} />
                <Dimmer active={userDataFetching} inverted>
                    <Loader active={userDataFetching}></Loader>
                </Dimmer>
            </div>
        </>
    );
}