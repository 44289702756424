import React, { useEffect } from 'react';
import {useSelector} from 'react-redux';
import { Accordion } from 'semantic-ui-react';
import InfiniteView from '../../components/InfiniteView';
import './styles.css'

import {FAQ_PAGE_COUNT, fetchFAQ} from './actions';
import {getFAQFetching, getFAQ, getFAQHasMore} from './reducer';
import {setWindowScrollPosition} from "../../components/WindowDimensions";

export default function Products(props) {

    const {dispatch, userData, userLocation, mixpanel, routeAction, isPageReloaded} = props;

    const loadingFaq = useSelector((state) => getFAQFetching(state.faq));
    const faq = useSelector((state) => getFAQ(state.faq));
    const hasMoreFAQ = useSelector((state) => getFAQHasMore(state.faq));

    useEffect(() => {
        if (routeAction === 'PUSH' || isPageReloaded) {
            readFAQ(1);
            setWindowScrollPosition();
        }
        if (mixpanel)
            mixpanel.track('FAQ List View', {...userLocation, ...userData});
    }, []);

    const loadFAQ = () => {
        if (hasMoreFAQ) {
            const nextPage = Math.round(faq.length / FAQ_PAGE_COUNT) + 1;
            readFAQ(nextPage);
        }
    };

    const readFAQ = (page) => {
        dispatch(fetchFAQ({
            page,
            per_page: FAQ_PAGE_COUNT,
            app: 'SERVICER',
            order: 'asc',
            orderby: 'faq_order',
        }));
    };

    const accordionFAQ = () => (
        <Accordion
            className="style-accordion-FAQ"
            styled
            panels={faq.map((item, index) => ({
                key: index,
                title: item.question,
                content: {content: (<p className="accordion-content"
                                       dangerouslySetInnerHTML={{__html: item.answer.replace(/\n/g, '<br/>')}}/>)
                }
            }))}
        />
    )

    return (
        <InfiniteView
            headerTitle="Часто задаваемые вопросы"
            dataLength={faq.length}
            nextFetch={loadFAQ}
            hasMore={hasMoreFAQ}
            dataList={accordionFAQ()}
            mustAuth={false}
            isLoading={loadingFaq}
            loaderText="Загружаем вопросы ..."
            zeroText="Часто задаваемые вопросы отсутствуют"
        />
    );
}