import './styles.css';
import React, {useState, useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {Dropdown, Dimmer, Loader} from 'semantic-ui-react';
import { ReactComponent as BackIcon } from "../../icons/back-icon.svg";
import { ReactComponent as BoxArrow } from "../../icons/box-arrow.svg";
import { setWindowScrollPosition } from "../../components/WindowDimensions";
import { getTasksFetching, getTasks, getTasksHasMore, getTaskFilter, getTaskOptions, getTaskOrderBy } from '../Tasks/reducer';
import { setYear } from "../Profile/actions";
import { fetchUserData } from '../../components/UserLogin/actions';

const monthNames = {
  '01': 'Январь',
  '02': 'Февраль',
  '03': 'Март',
  '04': 'Апрель',
  '05': 'Май',
  '06': 'Июнь',
  '07': 'Июль',
  '08': 'Август',
  '09': 'Сентябрь',
  '10': 'Октябрь',
  '11': 'Ноябрь',
  '12': 'Декабрь'
};

const yearOptions = [
    { key: 0, text: '2025', value: '2025'},
    { key: 1, text: '2024', value: '2024'},
]


export default function ServicePayment(props) {
    const { userData, navigate, token, dispatch } = props

    const stateYear = useSelector(state => state.yearReducer.selectedYear)
    const userTasks = userData.profiles.tasks.totals_earn.months

    const [userDataFetching, setUserDataFetching] = useState(false);
    useEffect(() => {
        if (token) {
            setUserDataFetching(true);
            dispatch(fetchUserData(token, 'ru', stateYear))
                .finally(() => setUserDataFetching(false));
        } else {
            navigate('/');
        }
    }, [token, stateYear, dispatch]);


    const handleChangeYear = (e, {value}) => {
        dispatch(setYear(value));
        setUserDataFetching(true)
        dispatch(fetchUserData(token, 'ru', value))
            .finally(() => setUserDataFetching(false))
    }

    const PaymentContainer = (props) => {
        const { services } = props
        const date = services[0];

        const total = services[1].tasks.reduce((accumulator, currentValue) => accumulator + currentValue.earn, 0);
        const [open, setOpen] = useState(false)


        const [maxHeight, setMaxHeight] = useState("0px");
        const [padding, setPadding] = useState("0px");
        const containerRef = useRef(null);

        useEffect(() => {
            if (open) {
                setMaxHeight(`${containerRef.current.scrollHeight}px`);
            } else {
                setMaxHeight("0px");
                setPadding('0px');
            }
        }, [open]);

        return (
            <div className='service-payment-month-container'>
                <span className="payment-date">
                    {monthNames[date.split('-')[1]]+ ' ' +date.split('-')[0]}
                </span>
                <div className='payment-data'>
                    <div className="data-header">
                        <span className="services-number">
                            Количество услуг
                        </span>
                        <span className="services-amount">
                            Сумма
                        </span>
                    </div>
                        <div className="services-desc-box" onClick={() => setOpen(!open)}>
                            <span className="services-number">{services[1].tasks.length}</span>
                            <span className="services-amount">{total}₽
                                <BoxArrow className={`box-arrow ${open ? 'open' : ''}`} />
                            </span>
                        </div>
                    <div
                        className="container-services"
                        ref={containerRef}
                        style={{
                            maxHeight: maxHeight,
                            overflowY: services > 7 ? 'auto' : 'hidden' ,
                            transition: 'max-height 0.5s ease',
                            padding: padding,
                            marginTop: '5px'
                        }}
                    >
                        <div className={`container-services ${open ? 'open' : ''}`}>
                            {services[1].tasks.map(service =>
                                <div className="service-info" key={service.id_order}>
                                    <span className="service-id">№{service.id_order}</span>
                                    <span className="service-desc">{service.product_name}</span>
                                    <span className="service-price">{service.earn}₽</span>
                                </div>)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div className="service-payment-container">
            <div className='service-payment-header'>
                <BackIcon className='service-payment-header-img' onClick={() => navigate('/profile')}/>
                <div className='title-text'>Мои доходы</div>
                <Dropdown
                    style={{height: 'max-content', width: 'max-content', position: 'absolute', top: 0, right: 0, }}
                    fluid
                    name='Выберите год'
                    value={stateYear}
                    onChange={handleChangeYear}
                    search
                    selection
                    scrolling
                    noResultsMessage="Ничего не найдено"
                    options={yearOptions}
                />
            </div>
            <div>
                {Object.entries(userTasks).reverse().map(item => {
                  return <PaymentContainer services={item} />
                })}
            </div>
            <Dimmer active={userDataFetching} inverted>
                <Loader active={userDataFetching}></Loader>
            </Dimmer>
        </div>
    )
}
