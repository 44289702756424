import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Radio } from "semantic-ui-react";
import { ReactComponent as BackIcon } from "../../icons/back-icon.svg";
import { Dropdown, Dimmer, Loader } from 'semantic-ui-react';
import CalendarTable from "../../components/CalendarTable";
import PaginatedTasks from "../../components/PaginationTasks";
import {setYear} from "../Profile/actions";
import {fetchUserData} from "../../components/UserLogin/actions";

const yearOptions = [
    { key: 0, text: '2025', value: '2025'},
    { key: 1, text: '2024', value: '2024'},
]

const EmploymentCalendar = (props) => {
    const { routeAction, dispatch, token, userLocation, userData, mixpanel, isPageReloaded, navigate} = props;
    const [selectedPlanned, setSelectedPlanned] = useState('accepted');
    const [isLoading, setIsLoading] = useState(false);
    const stateYear = useSelector(state => state.yearReducer.selectedYear)

    useEffect(() => {
        if (token) {
            setIsLoading(true);
            dispatch(fetchUserData(token, 'ru', stateYear))
                .finally(() => setIsLoading(false));
        } else {
            navigate('/');
        }
    }, [token, stateYear, dispatch]);


    const handleChange = (e, { value }) => {
        setSelectedPlanned(value);
    };

    const handleChangeYear = (e, {value}) => {
        dispatch(setYear(value));
        setIsLoading(true)
        dispatch(fetchUserData(token, 'ru', value))
            .finally(() => setIsLoading(false))
    }

    return (
        <>
            <div className="service-payment-container">
                <div className="service-payment-header">
                    <BackIcon
                        className="service-payment-header-img"
                        onClick={() => navigate("/profile")}
                    />
                    <div className="title-text">Календарь занятости</div>
                    <Dropdown
                        style={{height: 'max-content', width: 'max-content', position: 'absolute', top: 0, right: 0, }}
                        fluid
                        name='Выберите год'
                        value={stateYear}
                        onChange={handleChangeYear}
                        search
                        selection
                        scrolling
                        noResultsMessage="Ничего не найдено"
                        options={yearOptions}
                    />
                </div>

                <div className="checkboxes-box" style={{ marginBottom: "10px" }}>
                    <Radio
                        label="Запланированные"
                        name="radioGroup"
                        value="accepted"
                        checked={selectedPlanned === "accepted"}
                        onChange={handleChange}
                        className={"checkbox-box"}
                    />
                    <Radio
                        label="Завершенные"
                        name="radioGroup"
                        value="done"
                        checked={selectedPlanned === "done"}
                        onChange={handleChange}
                        className={"checkbox-box"}
                    />
                </div>
            </div>

            <CalendarTable radioState={selectedPlanned}/>

            <div className="profile-tasks" style={{ marginLeft: "2.5%" }}>
                <PaginatedTasks
                    status={"new"}
                />
            </div>
            <Dimmer inverted active={isLoading}>
                <Loader active={isLoading} />
            </Dimmer>
        </>
    );
};

export default EmploymentCalendar;