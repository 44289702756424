import React, {useEffect, useRef, useState} from 'react';

import './style.css';
import {ReactComponent as BackIcon} from "../../icons/back-icon.svg";
import {ReactComponent as EditOverlay} from "../../icons/edit-overlay.svg";
import {ReactComponent as OverlayIcon} from "../../icons/edit-overlay-icon.svg";

import {Button, Dimmer, Dropdown, Form, Icon, Loader, TextArea} from "semantic-ui-react";
import {EmailSuggestions, FioSuggestions} from 'react-dadata';
import {DADATA_API_KEY} from "../../config/secrets";

import PhoneCountryMask from "../../components/AddressCard/PhoneCountryMask";
import {
    ADDRESS_PHONE,
    ADDRESS_PHONE_COUNTRY,
    fetchCounties,
    setAddressFields
} from "../../components/AddressCard/actions";
import {useSelector} from "react-redux";

import {getAddressFields, getCountries, isCountriesFetching} from "../../components/AddressCard/reducer";
import {resetFormChanged, setFormChanged, setPopupClose, setPopupOpen} from "./actions";
import {updateUserData} from "../../components/UserLogin/actions";
import {toastr} from "react-redux-toastr";
import ModalPrompt from "../../components/ModalPrompt";
import {openAuth} from "../../components/NavTopBar/actions";
import {isAuthVisible} from "../../components/NavTopBar/reducer";
import {getUserData} from "../../components/UserLogin/reducer";



export default function EditProfile(props) {


    const {navigate, dispatch, token, location, isLoggedIn} = props;
    const userData = useSelector(state => state.userLogin.userData);
    const isFetchingCountries = useSelector((state) => isCountriesFetching(state.address));
    const countries = useSelector((state) => getCountries(state.address));
    const isPopupOpen = useSelector(state => state.formReducer.isPopupOpen);
    const isFormChanged = useSelector((state) => state.formReducer.isFormChanged);
    const route = useSelector(state => state.formReducer.actionRoute);
    const authVisible = useSelector((state) => isAuthVisible(state.navtopbar));
    const fields = useSelector((state) => getAddressFields(state.address));
    const [errors, setErrors] = useState({});
    const inputAvatarRef = useRef(null);
    const [isLoading, setLoading] = useState(false)
    const [pendingDeleteIndex, setPendingDeleteIndex] = useState(null);



    useEffect(() => {
        dispatch(fetchCounties());
        if (!token) {
            navigate('/');
        }
    }, [token, dispatch]);

    let listPhoneCountries = [];

    listPhoneCountries =
        countries.countries ?
            Object.values(countries.countries).map((element, index) => (
                {
                    key:  index,
                    text: element.country_ru,
                    flag: _.lowerCase(element.iso),
                    value: element.iso,
                    className: "dropdown-item",
                    phone_mask: element.phone_mask,
                }
            )) : [] ;

    const formFields = [
        'first_name',
        'last_name',
        'email',
        'phone_country',
        'phone',
        'country',
        'education',
        'speciality',
        'images',
        'interests'
    ];
    let profileInterests = userData.profiles.interests.filter(item => item.value === 1);

    const [formData, setFormData] = useState({
        avatar: userData.profiles.avatar,
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        phone: userData.phone,
        phone_country: userData.phone_country,
        country: userData.country,
        state: userData.state,
        speciality: userData.profiles.portfolio.speciality,
        education: userData.profiles.portfolio.education,
        interests: profileInterests,
        images: userData.profiles.images,
        // company: userData.billing.company,
        // company_job_title: userData.billing.company_job_title,
        // is_juridic: userData.billing.is_juridic,
        id: userData.id,
    });

    useEffect(() => {
        setFormData({
            avatar: userData.profiles.avatar,
            first_name: userData.first_name,
            last_name: userData.last_name,
            email: userData.email,
            phone: userData.phone,
            phone_country: userData.phone_country,
            country: userData.country,
            state: userData.state,
            speciality: userData.profiles.portfolio.speciality,
            education: userData.profiles.portfolio.education,
            interests: profileInterests,
            images: userData.profiles.images,
            // company: userData.billing.company,
            // company_job_title: userData.billing.company_job_title,
            // is_juridic: userData.billing.is_juridic,
            id: userData.id,
        })
    }, [userData])

    const [specialityLength, setSpecialityLength] = useState(formData.speciality.length);
    const [educationLength, setEducationLength] = useState(formData.education.length);
    const isFormValid = Object.values(errors).every(error => error === '');
    const [statusButton, setStatusButton] = useState(isFormValid);
    const [areInterestsValid, setInterestsValid] = useState(formData.interests.length > 0);

    useEffect(() => {
        setStatusButton(!isFormValid || !areInterestsValid);
    }, [errors, isFormValid, areInterestsValid]);

    const isValidEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    useEffect(() => {
        if (!countries || !countries.countries) {
            dispatch(fetchCounties());
        }
        mountDirectField([
            {name: 'countryPhoneCode', value: formData.phone_country},
            {name: 'phone', value: formData.phone}
        ]);
        dispatch(resetFormChanged());
        dispatch(setPopupClose());
        validateFields();


    }, []);

    useEffect(() => {
        setStatusButton(!isFormValid || !areInterestsValid);
    }, [errors, isFormValid, areInterestsValid]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            e.preventDefault();
            dispatch(resetFormChanged());
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [formData, userData]);

    const submitUserData = (e) => {
        e.preventDefault();
        setLoading(true);
        const query = JSON.stringify({
            avatar: formData.avatar,
            billing: {
                first_name: formData.first_name,
                last_name: formData.last_name,
                email: formData.email,
                phone: formData.phone,
                phone_country: formData.phone_country,
                country: formData.country.toUpperCase(),
                state: formData.state
            },
            images: formData.images,
            speciality: formData.speciality,
            education: formData.education,
            interests: formData.interests.reduce((acc, item) => acc + item.id + ',', '')
        })
        dispatch(updateUserData(props.token, formData.id, query, false))
            .then(() => {
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
        dispatch(resetFormChanged());

    }


    const checkFormChanges = () => {
        const isAvatarChanged = formData['avatar'] !== userData['avatar'];
        const isFieldChanged = formFields.some(field => formData[field] !== userData[field]);
        //console.log(isFieldChanged)
        if (isFieldChanged || isAvatarChanged) {
            dispatch(setFormChanged());
        } else {
            dispatch(resetFormChanged());
        }
    }

    const validateFields = () => {
        formFields.forEach(field => {
            validate(field, formData[field]);
        })
    }

    function updateField() {
        formData.phone_country = fields[ADDRESS_PHONE_COUNTRY];
        formData.phone = fields[ADDRESS_PHONE];
        dispatch(setAddressFields(fields));
        checkFormChanges();
    }

    //Заполнение полей с телефонной масокй при загрузке страницы
    const mountDirectField = (data) => {
        data.forEach((element) => {
            fields[element.name] = element.value;
        });
        updateField();
    }

    const handleFirstNameChange = (value) => {
        validate('first_name', value.value);
        setFormData({
            ...formData,
            first_name: value.value,
        });
        checkFormChanges();
    }

    const handleSpecialityChange = (value) => {
        const newValue = value.target.value;
        validate('speciality', newValue);
        if (newValue.length <= 300) {
            validate('speciality', newValue);
            setFormData({
                ...formData,
                speciality: newValue,
            });
            setSpecialityLength(newValue.length);
            checkFormChanges();
        }
    };

    const handleEducationChange = (value) => {
        const newValue = value.target.value;
        validate('education', newValue);
        if (newValue.length <= 300) {
            validate('education', newValue);
            setFormData({
                ...formData,
                education: newValue,
            });
            setEducationLength(newValue.length);
            checkFormChanges();
        }
    };

    const handleInterestsChange = (e, { value }) => {
        setFormData({
            ...formData,
            interests: value,
        });
        setInterestsValid(value.length > 0); // Проверка, выбраны ли интересы
        // console.log(value)
        checkFormChanges();
    }

    const handleLastNameChange = (value) => {
        validate('last_name', value.value);
        setFormData({
            ...formData,
            last_name: value.value,
        });
        checkFormChanges();
    }

    const handleEmailChange = (value) => {
        validate('email', value.value);
        setFormData({
            ...formData,
            email: value.value,
        });
        checkFormChanges();
    }

    const setDirectField = (name, data) => {
        if (name === 'countryPhoneCode') {
            fields['phone'] = '';
        }
        fields[name] = data;
        updateField();
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        validate(name, value);
        setFormData({
            ...formData,
            [name]:  value,
        });
        // console.log(formData)
        checkFormChanges();
    }

    const handleImageClick = () => {
        inputAvatarRef.current.click();
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        const reader = new FileReader();
        reader.onload = () => {
            const base64Url = reader.result;

            setFormData({
                ...formData,
                avatar: base64Url
            });

            checkFormChanges();
        };
        reader.readAsDataURL(file);
        console.log(formData.avatar)
    }

    const validate = (fieldName, value) => {
        let fieldErrors = {};
        if (fieldName === 'first_name') {
            if (!value) {
                fieldErrors = { [fieldName]: "Имя является обязательным для заполнения" };
            } else {
                fieldErrors = { [fieldName]: ''};
            }
        }
        if (fieldName === 'last_name') {
            if (!value) {
                fieldErrors = { [fieldName]: "Фамилия является обязательным для заполнения"};
            } else {
                fieldErrors = { [fieldName]: ''};
            }
        }
        if (fieldName === 'email') {
            if (!isValidEmail(value) && value !== '') {
                fieldErrors = { [fieldName]: "Почта не является корректной"};
            }
                // else if (!value) {
                //     fieldErrors = { [fieldName]: "Почта является обязательной для заполнения"};
            // }
            else {
                fieldErrors = { [fieldName]: ''};
            }
        }

        if (fieldName === 'phone') {
            if (!value) {
                fieldErrors = { [fieldName]: "Телефон является обязательным для заполнения"};
            } else {
                fieldErrors = { [fieldName]: ''};
            }
        }

        setErrors((prevErrors) => ({ ...prevErrors, ...fieldErrors }));
    }

    const phoneMask = listPhoneCountries.find(item => item.value === formData.phone_country)?.phone_mask;

    const interests = userData.profiles.interests.map(interest => ({
        value: interest,
        text: interest.name,
        id: interest.id,
    }));


    const [draggedIndex, setDraggedIndex] = useState(null)
    const [draggable, setDraggable] = useState(false)

    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        const newImages = Promise.all(files.map(file => {
            const reader = new FileReader();
            return new Promise((resolve) => {
                reader.onload = () => resolve(reader.result);
                reader.readAsDataURL(file);
            });
        }));

        newImages.then(images => {
            setFormData(prevFormData => ({
                ...prevFormData,
                images: [...prevFormData.images, ...images]
            }));
        });
    };

    const handleImageDelete = (index) => {
        setPendingDeleteIndex(index);

        setTimeout(() => {
            setFormData(prevFormData => ({
                ...prevFormData,
                images: prevFormData.images.filter((_, i) => i !== index)
            }));
            setPendingDeleteIndex(null);
        }, 0);
    };

    const handleDragStart = (index) => {
        if (draggable === true) {
            setDraggedIndex(index);
        }
    };

    const handleDragOver = (e) => {
        if (draggable === true) {
            e.preventDefault();
        }
    };

    const handleDrop = (index) => {
        if (draggedIndex !== null) {
            const newImages = [...formData.images];
            const [draggedImage] = newImages.splice(draggedIndex, 1);
            newImages.splice(index, 0, draggedImage);
            setFormData(prevFormData => ({
                ...prevFormData,
                images: newImages,
            }));
            setDraggedIndex(index);
        }
    };

    return (
        <>
            <div className='edit-profile-container'>
                <div className='edit-profile-header'>
                    <BackIcon style={{cursor: 'pointer'}} className='edit-profile-header-img' onClick={
                        isLoggedIn
                            ? (isFormChanged && location.pathname === '/edit-profile'
                                ? () => dispatch(setPopupOpen("/profile"))
                                : () => navigate("/profile"))
                            : () => {
                                if (!authVisible)
                                    dispatch(openAuth())
                            }
                    }/>
                    <span className='edit-profile-header-text'>Редактировать профиль</span>
                </div>
                <div className='edit-profile-body'>
                    <div>
                        <div className="edit-icon-container">
                            <img src={formData.avatar} alt="Аватар" className="edit-profile-body-img"/>
                            <div className='edit-overlay' onClick={handleImageClick}>
                                <span className='overlay-text'>
                                    <OverlayIcon width={10} height={10} />
                                    Изменить
                                </span>
                                <EditOverlay width={131} style={{position: 'absolute', inset: '0'}}/>
                            </div>
                        </div>
                        <input type="file" name="avatar" accept="image/png, image/gif, image/jpeg" ref={inputAvatarRef}
                               onChange={handleImageChange} style={{display: 'none'}}/>
                    </div>
                    <div className='container-inputs-edit'>

                        <div className='container-inp-one'>

                            <div className='bio-edit-container'>
                                <span className='bio-name-edit'>Фамилия</span>
                                <FioSuggestions
                                    token={DADATA_API_KEY}
                                    inputProps={{
                                        className: 'edit-input',
                                        name: 'last_name',
                                        id: 'last_name',
                                        placeholder: 'Фамилия*',
                                        required: true,
                                        onChange: handleChange
                                    }}
                                    defaultQuery={formData.last_name}
                                    count='4'
                                    filterParts={['surname']}
                                    onChange={handleLastNameChange}
                                />
                                {/*{errors.last_name && <span className='edit-error'>{errors.last_name}</span>}*/}
                            </div>


                            <div className='bio-edit-container'>
                                <span className='bio-name-edit'>Имя</span>
                                <FioSuggestions
                                    token={DADATA_API_KEY}
                                    inputProps={{
                                        className: 'edit-input',
                                        name: 'first_name',
                                        id: 'first_name',
                                        placeholder: "Имя*",
                                        required: true,
                                        onChange: handleChange
                                    }}
                                    defaultQuery={formData.first_name}
                                    count='4'
                                    filterParts={['name']}
                                    onChange={handleFirstNameChange}
                                />
                                {/*{errors.first_name && <span className='edit-error'>{errors.first_name}</span>}*/}
                            </div>

                        </div>

                        <div className='container-inp-two'>
                            <div className='bio-edit-container'>
                                <span className='bio-name-edit'>Телефон</span>
                                <PhoneCountryMask
                                    extraClass="bio-input-phone"
                                    name="phone"
                                    countries={listPhoneCountries}
                                    country={formData.phone_country}
                                    loading={isFetchingCountries}
                                    phone={formData.phone}
                                    phoneMask={phoneMask}
                                    handleChangeField={setDirectField}
                                    nameCountry={ADDRESS_PHONE_COUNTRY}
                                    namePhone={ADDRESS_PHONE}
                                    fluid={true}
                                    required
                                />
                                {errors.phone && <span className='edit-error'>{errors.phone}</span>}
                            </div>


                            <div className='bio-edit-container'>
                                <span className='bio-name-edit'>Почта</span>
                                <EmailSuggestions
                                    token={DADATA_API_KEY}
                                    inputProps={{
                                        className: 'edit-input',
                                        name: 'email',
                                        id: 'email',
                                        placeholder: "Почта*",
                                        required: true,
                                        onChange: handleChange
                                    }}
                                    defaultQuery={formData.email}
                                    count='4'
                                    onChange={handleEmailChange}
                                />
                                {errors.email && <span className='edit-error'>{errors.email}</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='edit-profile-interests'>
                    <span className='text-interests'>Личные интересы</span>
                    <Dropdown
                        selection
                        className='interest-dropdown'
                        placeholder="Выберите Ваши интересы из списка"
                        multiple
                        clearable
                        value={formData.interests}
                        onChange={handleInterestsChange}
                        noResultsMessage="Ничего не найдено"
                        options={interests}
                    />
                    { formData.interests.length < 1 ? <span style={{color: 'red'}}>Поле является обязательным для заполнения</span> : '' }
                </div>
                <div className='edit-profile-speciality'>
                    <span className='text-speciality'>Специализация</span>
                    <Form className="speciality-form">
                        <TextArea
                            type="text"
                            placeholder="Пожалуйста, расскажите о своей специализации"
                            value={formData.speciality}
                            onChange={handleSpecialityChange}
                            className="speciality-text-area"
                            transparent/>
                        <div className='character-count'>
                            {`${specialityLength} / 300`}
                        </div>
                        {/*{specialityLength < 150 && (*/}
                        {/*    <span className='character-warning' style={{color: 'red'}}>*/}
                        {/*        Поле должно содержать минимум 150 символов*/}
                        {/*    </span>*/}
                        {/*)}*/}
                    </Form>
                </div>
                <div className='edit-profile-speciality'>
                    <span className='text-speciality'>Образование и достижения</span>
                    <Form className="speciality-form">
                        <TextArea
                            type="text"
                            placeholder="Пожалуйста, расскажите о своем образовании и достижениях"
                            value={formData.education}
                            onChange={handleEducationChange}
                            className="speciality-text-area"
                            transparent/>
                        <div className='character-count'>
                            {`${educationLength} / 300`}
                        </div>
                        {/*{educationLength < 150 && (*/}
                        {/*    <span className='character-warning' style={{color: 'red'}}>*/}
                        {/*        Поле должно содержать минимум 150 символов*/}
                        {/*    </span>*/}
                        {/*)}*/}
                    </Form>
                </div>
                <div className='photogallery-container'>
                    <span className='text-speciality'>Загрузите фотографии в Вашу галерею</span>
                    <input
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={handleImageUpload}
                        style={{display: 'none'}}
                        id="imageUpload"
                    />
                    <div className='upload-images-box'>
                        <div className='upload-images-btns'>
                            <label htmlFor="imageUpload" className='upload-button'>
                                Загрузить
                            </label>
                            { formData.images.length < 2 ? '' : (
                                <Button
                                    className='dragg-button'
                                    onClick={() => setDraggable(!draggable)}>
                                    {draggable ? "Сохранить" : "Поменять расположение"}
                                </Button>
                            )}
                        </div>

                        <div className='image-gallery'>
                            {formData.images.map((image, index) => (
                                <div
                                    key={image}
                                    className="image-item"
                                    draggable
                                    onDragStart={() => handleDragStart(index)}
                                    onDragOver={handleDragOver}
                                    onDrop={() => handleDrop(index)}
                                >
                                    <img src={image} alt={`Фотография ${index}`}/>
                                    <div className="div-trash" onClick={() => handleImageDelete(index)}>
                                        <Icon name="trash alternate outline" className="icon-trash" color="red"/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='save-btn-box'>
                    <Button className='save-btn' onClick={submitUserData} disabled={statusButton}>Сохранить</Button>
                </div>
                <ModalPrompt
                    open={isPopupOpen}
                    headerText="Выход"
                    messageText="Вы уверены что хотите выйти без сохранения?"
                    handleYes={() => {
                        dispatch(setPopupClose());
                        dispatch(resetFormChanged());
                        navigate(route);
                    }}
                    handleNo={() => {
                        dispatch(setPopupClose())
                    }}
                    handleClose={() => {
                        dispatch(setPopupClose());
                    }}
                />
                <Dimmer active={isLoading} inverted>
                    <Loader active={isLoading}>Сохраняем данные...</Loader>
                </Dimmer>
            </div>
        </>
    )
}
