import 'whatwg-fetch';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import config, {
    AUTH_INVALID_MESSAGE,
    AUTH_INVALID_TOKEN,
    SERVICE_LOGICAL_ACCESS_DENIED,
} from '../../config/config';
import {dropToken} from '../../components/UserLogin/actions';
import {receiveSearchUpdate, SEARCH_CONTEXT_TASKS} from "../Search/actions";
import {isSearchView} from "../Search";

export const SET_TASK_OPTIONS = 'SET_TASK_OPTIONS';
export const CHANGE_TASK = 'CHANGE_TASK';
export const RECEIVE_CHANGED_TASK = 'RECEIVE_CHANGED_TASK';
export const REQUEST_TASKS = 'REQUEST_TASKS';
export const RECEIVE_TASKS = 'RECEIVE_TASKS';
export const RECEIVE_1PAGE_TASKS = 'RECEIVE_1PAGE_TASKS';
export const TASKS_PAGE_COUNT = 10;
export const SET_TASK_FILTER = 'SET_TASK_FILTER';
export const SET_TASK_ORDERBY  = 'SET_TASK_ORDERBY';

export const setTaskOrderBy = (orderby) => ({
    type: SET_TASK_ORDERBY,
    orderby,
});
export const setTaskFilter = (filter) => ({
    type: SET_TASK_FILTER,
    filter,
});
export const setTaskOptions = (options) => ({
  type: SET_TASK_OPTIONS,
  options,
});
export const changeTask = (id) => ({
  type: CHANGE_TASK,
  id,
});
export const receiveChangedTask = (tasks) => ({
    type: RECEIVE_CHANGED_TASK,
    tasks,
});
export const requestTasks = (page) => ({
  type: REQUEST_TASKS,
    page
});
export const receiveTasks = (tasks) => ({
  type: RECEIVE_TASKS,
  tasks,
});
export const receive1PageTasks = (tasks) => ({
  type: RECEIVE_1PAGE_TASKS,
  tasks,
});

const process_api_error = (json, dispatch) => {
  dispatch(receiveTasks([]));
  if (json.code === AUTH_INVALID_TOKEN) {
    dispatch(dropToken());
    toastr.error(AUTH_INVALID_MESSAGE);
  } else
      if (json.code === SERVICE_LOGICAL_ACCESS_DENIED)
          toastr.error(json.message);
      else
          toastr.error('Ошибка сервера: ' + String(json.code) + '. ' + json.message);
};

export const fetchTaskOptions = (token) => (dispatch) => {
  const headers = { Authorization: 'Bearer ' + token };
  let url = config.API_TASK_OPTIONS_URL;
  url += '?version=' + config.APP_VERSION;

  return fetch(url, { headers })
      .then((response) => response.json())
      .then((json) => {
        if (json.code === 200) {
          dispatch(setTaskOptions(json.data));
        } else {
          process_api_error(json, dispatch);
        }
      })
      .catch( (ex) => toastr.error('Ошибка запроса: ' + String(ex)) );
};

export const fetchTasks = (token, params = {}) => (dispatch) => {
  dispatch(requestTasks(params.page ?? 1));
  const headers = { Authorization: 'Bearer ' + token };
  let url;
  if (params && params.id) {
    url = config.API_TASK_URL + '?id=' + String(params.id);
  } else {
    url = config.API_TASKS_URL
      + '?'
      + Object.keys(params)
        .map((k) => k + '=' + encodeURIComponent(params[k]))
        .join('&');
  }
  url += '&version=' + config.APP_VERSION;

  return fetch(url, { headers })
    .then((response) => response.json())
    .then((json) => {
      if (json.code === 200) {
        dispatch(params.page === 1 ? receive1PageTasks(json.data) : receiveTasks(json.data));
      } else {
        process_api_error(json, dispatch);
      }
    })
    .catch( (ex) => toastr.error('Ошибка запроса: ' + String(ex)) );
};

export const updateTask = (token, id, operationCode, fromRoute, value = '') => (dispatch) => {
  dispatch(changeTask(id));
  const headers = { Authorization: 'Bearer ' + token };
  let url = config.API_TASK_UPDATE_URL +
      '?id=' + id + '&operation=' + operationCode + (value === '' ? '' : '&value=' + value);
  url += '&version=' + config.APP_VERSION;

  return fetch(url, { method: 'post', headers })
    .then((response) => response.json())
    .then((json) => {
      if (json.code === 200) {
        if (isSearchView(fromRoute)) {
            dispatch(receiveSearchUpdate(SEARCH_CONTEXT_TASKS, 0, json.data));
        }
        dispatch(receiveChangedTask(json.data));
        toastr.success('Статус задачи успешно обновлен!');
      } else {
        process_api_error(json, dispatch);
      }
    })
    .catch( (ex) => toastr.error('Ошибка запроса: ' + String(ex)) );
};

export const uploadFile = (token, formObj, dataURI) => (dispatch) => {
  dispatch(changeTask(formObj.taskID));
  const headers = { Authorization: 'Bearer ' + token };
  let url = config.API_FILE_UPLOAD_URL;
  url += '?version=' + config.APP_VERSION;
  const formData = new FormData();
  _.each( formObj, (value, key) => formData.append(String(key), value) );
  formData.append("dataURI", dataURI);
  return fetch(url, { method: 'post', headers, body: formData, })
    .then((response) => response.json())
    .then((json) => {
      if (json.code === 200) {
        dispatch(updateTaskStep(token, {...formObj, ...json.data}));
      } else {
        process_api_error(json, dispatch);
      }
    })
    .catch( (ex) => toastr.error('Ошибка запроса: ' + String(ex)) );
};

export const updateTaskStep = (token, formObj) => (dispatch) => {
  dispatch(changeTask(formObj.taskID));
  const headers = { Authorization: 'Bearer ' + token };
  let url = config.API_TASK_STEP_UPDATE_URL;
  url += '?version=' + config.APP_VERSION;
  const formData = new FormData();
  _.each( formObj, (value, key) => formData.append(String(key), value) );
  return fetch(url, { method: 'post', headers, body: formData, })
    .then((response) => response.json())
    .then((json) => {
      if (json.code === 200) {
        dispatch(receiveChangedTask(json.data));
        toastr.success('Изменения успешно сохранены!');
      } else {
        process_api_error(json, dispatch);
      }
    })
    .catch( (ex) => toastr.error('Ошибка запроса: ' + String(ex)) );
};

export const updateTaskDecision = (token, taskID, stateDecision) => (dispatch) => {
  dispatch(changeTask(taskID));
  const headers = { Authorization: 'Bearer ' + token };
  let url = config.API_TASK_UPDATE_DECISION_URL;
  url += '?version=' + config.APP_VERSION;
  const formData = new FormData();
  formData.append('id', taskID );
  formData.append('message', stateDecision.message );
  const list = (stateDecision.recommendedList.filter(Number)).join(',');
  formData.append('recommended_list', list );
  return fetch(url, { method: 'post', headers, body: formData, })
    .then((response) => response.json())
    .then((json) => {
      if (json.code === 200) {
        dispatch(receiveTasks(json.data));
        toastr.success('Изменения успешно сохранены!');
      } else {
        process_api_error(json, dispatch);
      }
    })
    .catch( (ex) => toastr.error('Ошибка запроса: ' + String(ex)) );
};
