import React, {useState,useEffect} from 'react';
import './styles.css'
import {Rating, Dropdown, Dimmer, Loader} from "semantic-ui-react";
import {ReactComponent as ArrowIcon} from "../../icons/arrow-profile-icon.svg";
import DoubleLineChart from "../../components/GraphComponent";
import {useSelector} from "react-redux";
import {fetchUserData} from "../../components/UserLogin/actions";
import {getUserFetching} from "../../components/UserLogin/reducer";
import { setYear } from './actions';


const yearOptions = [
    { key: 0, text: '2025', value: '2025'},
    { key: 1, text: '2024', value: '2024'},
]

export default function Profile(props) {
    const {navigate, token, dispatch} = props;
    const userData = useSelector(state => state.userLogin.userData);

    // Крутилка при загрузке данных юзера
    const [userDataFetching, setUserDataFetching] = useState(false);

    // Актуальный год и месяц вывод: 2025-02
    const date = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}`;

    // Актуальный год вывод: 2025
    const dateYear = new Date().getFullYear() + '';

    // Акутальный месяц вывод: -02
    const dateMonth = `-${String(new Date().getMonth() + 1).padStart(2, '0')}`;

    // Стейт выбранного года
    const stateYear = useSelector(state => state.yearReducer.selectedYear)
    // Объект с заказами по месяцам
    const totalEarn = {
        ...userData.profiles.tasks.totals_earn.months
    }

    // Массив с заказами
    const filteredTotalEarn = Object.keys(totalEarn).map(key => totalEarn[key]);

    // Объект с годовыми результами по заказам
    const totalYear =  {
        earned_sum: userData.profiles.tasks.totals_earn.earned_sum,
        total_closed: userData.profiles.tasks.totals_earn.total_closed,
        total_in_work: userData.profiles.tasks.totals_earn.total_in_work,
    }

    // Объект с заказами по месяцам + за год
    const totalEarnWithYear = {
        [stateYear]: totalYear,
        ...totalEarn
    }

    // Стейт для dropdown с выбором месяца
    const [statDate, setStatDate] = useState(stateYear + dateMonth);

    // Массив для dropdown если выбран актуальный год
    const dateOptionsThisYear = [
        { key: 0, text: 'За этот месяц', value: stateYear + dateMonth },
        { key: 1, text: 'Январь', value: `${stateYear}-01` },
        { key: 2, text: 'Февраль', value: `${stateYear}-02` },
        { key: 3, text: 'Март', value: `${stateYear}-03` },
        { key: 4, text: 'Апрель', value: `${stateYear}-04` },
        { key: 5, text: 'Май', value: `${stateYear}-05` },
        { key: 6, text: 'Июнь', value: `${stateYear}-06` },
        { key: 7, text: 'Июль', value: `${stateYear}-07` },
        { key: 8, text: 'Август', value: `${stateYear}-08` },
        { key: 9, text: 'Сентябрь', value: `${stateYear}-09` },
        { key: 10, text: 'Октябрь', value: `${stateYear}-10` },
        { key: 11, text: 'Ноябрь', value: `${stateYear}-11` },
        { key: 12, text: 'Декабрь', value: `${stateYear}-12` },
        { key: 13, text: 'За год', value: `${stateYear}` }
    ];

    // Массив для dropdown если выбран не актульный год
    const dateOptionsAnotherYear = [
        { key: 0, text: 'Январь', value: `${stateYear}-01` },
        { key: 1, text: 'Февраль', value: `${stateYear}-02` },
        { key: 2, text: 'Март', value: `${stateYear}-03` },
        { key: 3, text: 'Апрель', value: `${stateYear}-04` },
        { key: 4, text: 'Май', value: `${stateYear}-05` },
        { key: 5, text: 'Июнь', value: `${stateYear}-06` },
        { key: 6, text: 'Июль', value: `${stateYear}-07` },
        { key: 7, text: 'Август', value: `${stateYear}-08` },
        { key: 8, text: 'Сентябрь', value: `${stateYear}-09` },
        { key: 9, text: 'Октябрь', value: `${stateYear}-10` },
        { key: 10, text: 'Ноябрь', value: `${stateYear}-11` },
        { key: 11, text: 'Декабрь', value: `${stateYear}-12` },
        { key: 12, text: 'За год', value: `${stateYear}` }
    ]
    // Стейт для последних 4 задач
    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        if (token) {
            setUserDataFetching(true);
            dispatch(fetchUserData(token, 'ru', stateYear))
                .finally(() => setUserDataFetching(false));
        } else {
            navigate('/');
        }
    }, [token, stateYear, dispatch]);

    useEffect(() => {
        if (!userData.profiles?.tasks?.last_tasks) return;
        const arrayTask = Object.values(userData.profiles.tasks.last_tasks).map((item, index) => (
            <div
                key={item.id_task}
                className='profile-task'
                style={{
                    backgroundColor: index % 2 === 0 ? '#F6FCFF' : '#EBF6FF',
                    borderRadius: index === 0 ? '10px 10px 0 0' : index === 3 ? '0 0 10px 10px' : '0 0 0 0',
                }}
                onClick={() => navigate(`/task/${item.id_task}`)}
            >
                <div className='profile-task-number'>№{item.id_order}</div>
                <div className='profile-task-text'>{item.product_name}</div>
                <div className='profile-task-company'>{item.customer_name}</div>
            </div>
        ));

        setTasks(arrayTask);
    }, [userData]);

    // Функция для изменения значения в dropdown с месяцами
    const handleChangeStatDate = (e, {value}) => {
        setStatDate(value + '');
    }

    // Фукнция для измения значения в dropdown c годом
    const handleChangeYear = (e, {value}) => {
        dispatch(setYear(value));
        value === dateYear ? setStatDate(value + dateMonth) : setStatDate(value)
        setUserDataFetching(true)
        dispatch(fetchUserData(token, 'ru', value))
            .finally(() => setUserDataFetching(false))
    }


    return (
        <div className='profile-view'>
            <div className='profile-header-ctn'>
                <h1 className='profile-title-h1'>Личный кабинет</h1>
                <Dropdown
                    style={{height: 'max-content', width: 'max-content', position: 'absolute', top: 0, right: 0, }}
                    fluid
                    name='Выберите год'
                    value={stateYear}
                    onChange={handleChangeYear}
                    search
                    selection
                    scrolling
                    noResultsMessage="Ничего не найдено"
                    options={yearOptions}
                />
            </div>
            <div className='profile-header'>
                <div className='profile-icon-container'>
                    <img src={userData.profiles.avatar} alt='Аватар' className='profile-icon'></img>
                    <div className='profile-rating-conainer'>
                        <div className='profile-rating'>
                            <div className='profile-rating-text'>от клиентов</div>
                            <Rating icon='star' disabled maxRating={5} rating={userData.profiles.rating.customer}
                                    size='large'></Rating>
                        </div>
                        <div className='profile-rating'>
                            <div className='profile-rating-text'>от специалистов</div>
                            <Rating icon='star' disabled maxRating={5} rating={userData.profiles.rating.admin}
                                    size='large'></Rating>
                        </div>
                    </div>
                </div>
                <div className='profile-nav-container'>
                    <div className='profile-name'>{userData.first_name + ' ' + userData.last_name}</div>
                    <div className='profile-divider'></div>
                    <nav className='profile-nav'>
                        <div className='profile-nav-item' onClick={() => navigate('/edit-profile')}>
                            <div className='profile-nav-item-text'>Редактировать профиль</div>
                            <ArrowIcon style={{cursor: 'pointer'}}/>
                        </div>
                        <div className='profile-nav-item' onClick={() => navigate(`/service-payment`)}>
                            <div className='profile-nav-item-text'>Мои доходы</div>
                            <ArrowIcon style={{cursor: 'pointer'}}/>
                        </div>
                        <div className='profile-nav-item' onClick={() => navigate('/my-reviews')}>
                            <div className='profile-nav-item-text'>Мои отзывы</div>
                            <ArrowIcon style={{cursor: 'pointer'}}/>
                        </div>
                        <div className='profile-nav-item'>
                            <div className='profile-nav-item-text'
                                 onClick={() => navigate('/employment-calendar')}>Календарь
                            </div>
                            <ArrowIcon style={{cursor: 'pointer'}}/>
                        </div>
                    </nav>
                </div>
            </div>
            <div className='profile-tasks'>
                <h2 className='profile-title-h2'>Последние активные задачи</h2>
                {tasks.length > 0 ?
                    <div className='profile-tasks-container'>
                        {tasks}
                    </div>
                    :
                    <div style={{marginLeft: "5px"}}>Нет задач</div>
                }
            </div>
            <div className='profile-stats'>
                <h2 className='profile-title-h2'>Статистика</h2>
                <Dropdown
                    style={{width: '100%', marginBottom: '20px'}}
                    fluid
                    name='Выберите месяц'
                    value={statDate}
                    onChange={handleChangeStatDate}
                    search
                    selection
                    scrolling
                    noResultsMessage="Ничего не найдено"
                    options={stateYear === dateYear ? dateOptionsThisYear : dateOptionsAnotherYear}
                />

                <div className='profile-stats-row'>
                    <div className='profile-stats-container'>
                        <div className='profile-stats-number' style={{backgroundColor: '#F6FCFF'}}>
                            {totalEarnWithYear[statDate] ? totalEarnWithYear[statDate].total_in_work : 0}
                        </div>
                        <div className='profile-stats-text'>Задач в работе</div>
                    </div>
                    <div className='profile-stats-container'>
                        <div className='profile-stats-number' style={{backgroundColor: '#EBF6FF'}}>
                            {totalEarnWithYear[statDate] ? totalEarnWithYear[statDate].total_closed : 0}
                        </div>
                        <div className='profile-stats-text'>Задач завершено</div>
                    </div>
                    <div className='profile-stats-container'>
                        <div className='profile-stats-number' style={{backgroundColor: '#F6FCFF'}}>
                            {totalEarnWithYear[statDate] ? totalEarnWithYear[statDate].earned_sum : 0}₽
                        </div>
                        <div
                            className='profile-stats-text'>{statDate === `${dateYear}` ? 'Поступления в этом году' : 'Поступления в этом месяце'}</div>
                    </div>
                </div>
            </div>
            <div className='profile-graph'>
                <h2 className='profile-title-h2'>Задачи за год</h2>
                <DoubleLineChart totalTasks={{...totalEarn}} currentYear={stateYear} />
            </div>
            <Dimmer active={userDataFetching} inverted>
                <Loader active={userDataFetching}></Loader>
            </Dimmer>
        </div>
    )
}
