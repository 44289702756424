import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import "./styles.css";
import { fetchTasks, TASKS_PAGE_COUNT } from "../../views/Tasks/actions";
import { useDispatch, useSelector } from "react-redux";
import {
    getTaskFilter,
    getTaskOptions,
    getTaskOrderBy,
    getTasks,
    getTasksHasMore,
} from "../../views/Tasks/reducer";
import { isArray } from "lodash";

const PaginatedTasks = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const tasks = useSelector((state) => getTasks(state.tasks));
    const taskOptions = useSelector((state) => getTaskOptions(state.tasks));
    let filter = useSelector((state) => getTaskFilter(state.tasks));
    let orderBy = useSelector((state) => getTaskOrderBy(state.tasks));
    let hasMore = useSelector((state) => getTasksHasMore(state.tasks));

    const { token } = props;
    const tasksPerPage = TASKS_PAGE_COUNT;
    const totalPages = Math.ceil(tasks.length / tasksPerPage);

    // Initialize filter with correct structure
    useEffect(() => {
        if (!filter || filter.length === 0) {
            const defaultFilter = [
                { key: "new", value: 1, disabled: 0 },
                { key: "waiting", value: 0, disabled: 0 },
                { key: "to-do", value: 0, disabled: 0 },
                { key: "assigned", value: 0, disabled: 1 },
                { key: "accepted", value: 0, disabled: 1 },
                { key: "in-progress", value: 0, disabled: 1 },
                { key: "for-testing", value: 0, disabled: 0 },
                { key: "done", value: 0, disabled: 0 },
                { key: "canceled", value: 0, disabled: 0 }
            ];

            dispatch({
                type: "SET_TASK_FILTER",
                filter: defaultFilter
            });
        }
    }, [dispatch, filter]);

    // Load initial tasks
    useEffect(() => {
        if (token && filter?.length > 0) {
            loadTasks();
        }
    }, [token, filter]);

    const loadTasks = async () => {
        if (isLoading || !token || !filter) return;

        try {
            setIsLoading(true);
            const page = Math.round(tasks.length / tasksPerPage) + 1;


            await dispatch(
                fetchTasks(token, {
                    page,
                    per_page: tasksPerPage,
                    orderby: encodeURIComponent(JSON.stringify(orderBy || [])),
                    filter: encodeURIComponent(JSON.stringify(filter))
                })
            );
        } catch (error) {
            console.error('Error loading tasks:', error);
        } finally {
            setIsLoading(false);
        }

    };

    const startIndex = (currentPage - 1) * tasksPerPage;
    const currentTasks = tasks.slice(startIndex, startIndex + tasksPerPage);

    const goToNextPage = async () => {
        if (currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1);
            if (currentPage === totalPages - 1 && hasMore && !isLoading) {
                await loadTasks();
            }
        }
    };

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
        }
    };

    const goToPage = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="paginated-tasks">
            <span className="title-text">Незапланированные задачи</span>
            {isLoading && <div className="loading">Загрузка...</div>}
            {currentTasks.length > 0 ? (
                <div className="profile-unplanned-tasks-container">
                    {currentTasks.map((item) => (
                        <div className="profile-task"
                             key={`${item.id_order}-${item.id}`}
                             onClick={() => navigate(`/task/${item.id}`)}
                        >
                            <div
                                className="profile-task-number"
                                style={{
                                    borderRadius: "10px 0 0 0",
                                    backgroundColor:
                                        item.id_order % 2 === 0 ? "#F6FCFF" : "#EBF6FF",
                                    width: isMobile ? "75px" : "",
                                }}
                            >
                                №{item.id_order}
                            </div>
                            <div className="employment-profile-task-text">
                                {item.product_name}
                            </div>
                            <div className="profile-task-company">
                                {item.customer_address.company}
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div style={{ marginLeft: "5px" }}>Нет задач</div>
            )}

            {totalPages > 1 && (
                <div
                    className="pagination-controls"
                    style={{
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                    }}
                >
                    <button
                        onClick={goToPreviousPage}
                        disabled={currentPage === 1}
                        className="pagination-button"
                        style={{ cursor: currentPage === 1 ? "not-allowed" : "pointer" }}
                    >
                        &#8592;
                    </button>

                    <div className="pagination-numbers" style={{ display: "flex", gap: "5px" }}>
                        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                            <button
                                key={page}
                                onClick={() => goToPage(page)}
                                className={`pagination-number ${currentPage === page ? "active" : ""}`}
                                style={{
                                    padding: "5px 10px",
                                    border: "1px solid #ccc",
                                    borderRadius: "4px",
                                    backgroundColor: currentPage === page ? "#007bff" : "#fff",
                                    color: currentPage === page ? "#fff" : "#000",
                                    cursor: "pointer",
                                }}
                            >
                                {page}
                            </button>
                        ))}
                    </div>

                    <button
                        onClick={goToNextPage}
                        disabled={!hasMore || isLoading}
                        className="pagination-button"
                        style={{ cursor: (!hasMore || isLoading) ? "not-allowed" : "pointer" }}
                    >
                        &#8594;
                    </button>
                </div>
            )}
        </div>
    );
};

export default PaginatedTasks;